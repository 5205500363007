<template>
  <default-layout>
    <div class="w-full">
      <div class="mb-24 flex flex-wrap items-center gap-14">
        <nuxt-link
          v-for="page of pages"
          :key="page.to"
          class="break-words rounded-16 border border-e6e7ec px-18 py-14 font-medium text-inherit transition-all hover:bg-e6e7ec/20"
          active-class="!border-2c58f3 !text-2c58f3 hover:!bg-transparent"
          :to="page.to"
        >
          {{ page.name }}
        </nuxt-link>
      </div>

      <slot />
    </div>
  </default-layout>
</template>

<script setup lang="ts">
import defaultLayout from "./default.vue"

const pages = [
  {
    name: "👤 Пользователи",
    to: "/admin/users",
  },
  {
    name: "⚙️ Конфигурация",
    to: "/admin/config",
  },
  {
    name: "📝 Тексты",
    to: "/admin/texts",
  },
  {
    name: "📄 Страницы",
    to: "/admin/pages",
  },
  {
    name: "📢 Промокоды для бесплатного доступа",
    to: "/admin/free-access-promo-codes",
  },
  {
    name: "📢 Скидочные промокоды",
    to: "/admin/discount-promo-codes",
  },
  {
    name: "💰 Платежи",
    to: "/admin/payments",
  },
]
</script>
